html,
body {
  overflow-x: hidden;
  font-size: 20px;
  line-height: 1.75;
  width: 100vw;
  max-width: 100vw;
  -webkit-text-size-adjust: none;
  touch-action: pan-y;
}

body {
  margin: 0;
  font-family: "Barlow Condensed", sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Anton", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
}

h2 {
  font-family: "Anton", sans-serif;
  font-size: 2rem;
  letter-spacing: -0.2px;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
}

h3 {
  font-family: "Anton", sans-serif;
  font-size: 1.75rem;
  letter-spacing: -0.2px;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
}

h4 {
  font-family: "Anton", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
}

h5 {
  font-family: "Anton", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
}

h6 {
  font-family: "Anton", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}

p,
a,
label,
li {
  font-size: 1rem;
  letter-spacing: -0.2px;
  font-weight: 300;
  color: #7c7b7b;
  margin: 0 0 16px;
  line-height: 1.5;
}

.vjs-theme-custom {
  --vjs-theme-custom--primary: #fff;
  --vjs-theme-custom--secondary: #fff;
}

.vjs-theme-custom .vjs-big-play-button {
  width: 70px;
  height: 70px;
  background: none;
  line-height: 70px;
  font-size: 80px;
  border: none;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
  color: var(--vjs-theme-custom--primary);
}

.vjs-theme-custom:hover .vjs-big-play-button,
.vjs-theme-custom.vjs-big-play-button:focus {
  background-color: transparent;
  color: #fff;
}
